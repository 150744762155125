<template>
  <div>
    <div class="row align-items-center">
      <div class="col-md-6">
        <h3>
          <b
            >All courses
            <span class="badge badge-primary">{{ courses.length }}</span></b
          >
        </h3>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Search" v-model="search.search_text" />
          </b-input-group>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <v-select
            id="categories"
            v-model="search.category_id"
            placeholder="Select Category"
            :options="categories"
            label="category__name"
            :reduce="(option) => option.category__id"
          >
            <template #option="{ category__name }">
              <span> {{ category__name }}</span>
            </template>
          </v-select>
        </b-card>
      </div>
    </div>
    <b-card
      v-for="(course, index) in courses"
      v-if="courses.length"
      :key="index"
    >
      <div class="row align-items-center">
        <div class="col-md-3">
          <img :src="course.photo_url" class="img-fluid rounded" alt="" />
        </div>
        <div class="col-md-6">
          <div class="my-1">
            <h3>
              <b>{{ course.name }}</b>
            </h3>
            <p>
              Teacher : <a href="#">{{ course.instructor_name }}</a>
            </p>
            <p v-if="course.specification">
              <b-badge
                variant="light-secondary"
                style="margin: 5px"
                v-for="(data, i) in course.specification.split('<|>')"
                :key="i + 'data'"
              >
                {{ data }}
              </b-badge>
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <h5 class="item-price mb-1 text-center">
            <span>
              Price :
              <b
                >৳
                {{
                  course.discount_price ? course.discount_price : course.price
                }}</b
              >,
            </span>
            <span
              class="text-primary"
              v-for="(course_info, index_data) in course_infos"
              :key="'info' + index_data"
              v-if="
                course_infos.length && course_info.course_course_id == course.id
              "
              >Commission :
              <b
                >{{ course_info.commission ? course_info.commission : 0 }}%</b
              ></span
            >
          </h5>
          <div class="row">
            <div class="col-md-6">
              <b-button
                variant="light"
                tag="a"
                :href="
                  'https://brightskills.com/course-details/' +
                  course.slug +
                  '?affiliate_token=' +
                  $store.state.affiliate_token
                "
                target="_blank"
                class="btn-wishlist w-100 mb-1"
              >
                <!-- <feather-icon icon="HeartIcon" class="mr-50 text-danger" /> -->
                <span>Go To Details</span>
              </b-button>
            </div>
            <div class="col-md-6">
              <b-button
                v-for="(course_info, index_data) in course_infos"
                :key="'info' + index_data"
                v-if="
                  course_infos.length &&
                  course_info.course_course_id == course.id
                "
                variant="light"
                tag="a"
                :href="course_info.link"
                target="_blank"
                class="btn-wishlist w-100 mb-1"
              >
                <span>Content</span>
              </b-button>
            </div>
          </div>
          <b-button
            variant="primary"
            @click="
              copyURL(
                'https://brightskills.com/course-details/' +
                  course.slug +
                  '?affiliate_token=' +
                  $store.state.affiliate_token
              )
            "
            class="btn-cart w-100"
          >
            <!-- <feather-icon icon="ShoppingCartIcon" class="mr-50" /> -->
            <span>Copy Link</span>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BBadge,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import vSelect from "vue-select";
import _ from "lodash";
import Ripple from "vue-ripple-directive";
export default {
  data() {
    return {
      courses: {},
      course_infos: {},
      categories: [],
      search: {
        category_id: "",
        course_level: "",
        course_type: "",
        instructor: "",
        review: "",
        search_text: "",
      },
      load_count: 1,
      category_text: "All Course",
    };
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.getResults();
    this.getDataResults();
  },
  methods: {
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.s("Copy Successfully Done :)");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    async getDataResults() {
      const course = await this.callApi("post", "/affiliate/course/get");
      if (course.status == 200) {
        this.course_infos = course.data.courses;
      }
    },
    getResults() {
      axios.defaults.headers.common["Authorization"] = "";
      axios
        .post("https://panel.brightskills.com/api/search/false/", this.search)
        .then((res) => {
          if (res.status == 200) {
            this.courses = res.data.courses;
            if (this.load_count == 1) {
              this.categories = res.data.categories;
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {});
    },
    SearchData: _.debounce(function () {
      this.load_count += 1;
      this.getResults();
    }, 1000),
  },
  components: {
    BCard,
    BButton,
    BBadge,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
  },
  watch: {
    "search.search_text": function (val) {
      this.SearchData();
    },
    "search.category_id": function (val) {
      this.SearchData();
    },
  },
};
</script>

<style></style>
