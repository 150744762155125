<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <sales-report />
    <hr />
    <Course />
  </div>
</template>

<script>
import SalesReport from "@/views/dashboard/partials/SalesReport.vue";
import Course from "@/views/partials/Course.vue";
export default {
  components: {
    Course,
    SalesReport,
  },
};
</script>

<style></style>
