var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('h3',[_c('b',[_vm._v("All courses "),_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(_vm.courses.length))])])])]),_c('div',{staticClass:"col-md-3"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":"Search"},model:{value:(_vm.search.search_text),callback:function ($$v) {_vm.$set(_vm.search, "search_text", $$v)},expression:"search.search_text"}})],1)],1)],1),_c('div',{staticClass:"col-md-3"},[_c('b-card',{attrs:{"no-body":""}},[_c('v-select',{attrs:{"id":"categories","placeholder":"Select Category","options":_vm.categories,"label":"category__name","reduce":function (option) { return option.category__id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var category__name = ref.category__name;
return [_c('span',[_vm._v(" "+_vm._s(category__name))])]}}]),model:{value:(_vm.search.category_id),callback:function ($$v) {_vm.$set(_vm.search, "category_id", $$v)},expression:"search.category_id"}})],1)],1)]),_vm._l((_vm.courses),function(course,index){return (_vm.courses.length)?_c('b-card',{key:index},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-3"},[_c('img',{staticClass:"img-fluid rounded",attrs:{"src":course.photo_url,"alt":""}})]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"my-1"},[_c('h3',[_c('b',[_vm._v(_vm._s(course.name))])]),_c('p',[_vm._v(" Teacher : "),_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(course.instructor_name))])]),(course.specification)?_c('p',_vm._l((course.specification.split('<|>')),function(data,i){return _c('b-badge',{key:i + 'data',staticStyle:{"margin":"5px"},attrs:{"variant":"light-secondary"}},[_vm._v(" "+_vm._s(data)+" ")])}),1):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('h5',{staticClass:"item-price mb-1 text-center"},[_c('span',[_vm._v(" Price : "),_c('b',[_vm._v("৳ "+_vm._s(course.discount_price ? course.discount_price : course.price))]),_vm._v(", ")]),_vm._l((_vm.course_infos),function(course_info,index_data){return (
              _vm.course_infos.length && course_info.course_course_id == course.id
            )?_c('span',{key:'info' + index_data,staticClass:"text-primary"},[_vm._v("Commission : "),_c('b',[_vm._v(_vm._s(course_info.commission ? course_info.commission : 0)+"%")])]):_vm._e()})],2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"btn-wishlist w-100 mb-1",attrs:{"variant":"light","tag":"a","href":'https://brightskills.com/course-details/' +
                course.slug +
                '?affiliate_token=' +
                _vm.$store.state.affiliate_token,"target":"_blank"}},[_c('span',[_vm._v("Go To Details")])])],1),_c('div',{staticClass:"col-md-6"},_vm._l((_vm.course_infos),function(course_info,index_data){return (
                _vm.course_infos.length &&
                course_info.course_course_id == course.id
              )?_c('b-button',{key:'info' + index_data,staticClass:"btn-wishlist w-100 mb-1",attrs:{"variant":"light","tag":"a","href":course_info.link,"target":"_blank"}},[_c('span',[_vm._v("Content")])]):_vm._e()}),1)]),_c('b-button',{staticClass:"btn-cart w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.copyURL(
              'https://brightskills.com/course-details/' +
                course.slug +
                '?affiliate_token=' +
                _vm.$store.state.affiliate_token
            )}}},[_c('span',[_vm._v("Copy Link")])])],1)])]):_vm._e()})],2)}
var staticRenderFns = []

export { render, staticRenderFns }