<template>
  <div>
    <div class="row justify-content-end my-2">
      <div class="col-md-8">
        <h3><b>Sales Overview</b></h3>
      </div>
      <div class="col-md-2">
        <DatePicker
          format="yyyy-MM-dd"
          type="date"
          name="from_date"
          v-model="search.from_date"
          id="from_date"
          placeholder="Select From Date"
          :clearable="false"
          style="width: 100%"
        ></DatePicker>
      </div>
      <div class="col-md-2">
        <DatePicker
          format="yyyy-MM-dd"
          type="date"
          name="to_date"
          v-model="search.to_date"
          id="to_date"
          placeholder="Select To Date"
          :clearable="false"
          style="width: 100%"
        ></DatePicker>
      </div>
    </div>
    <b-card no-body class="card-statistics">
      <b-card-body class="statistics-body">
        <b-row>
          <b-col>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="TrendingUpIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{ total_sales }}</h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Sales
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="CodepenIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ total_course_sales ? total_course_sales : 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Product
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  ৳ {{ affilitor_payment ? affilitor_payment : 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Commission
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      search: {
        from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to_date: new Date(),
      },
      total_sales: 0,
      total_course_sales: 0,
      affilitor_payment: 0,
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults() {
      const report = await this.callApi(
        "post",
        "/affiliate/dashboard/report",
        this.search
      );
      if (report.status == 200) {
        this.total_sales = report.data.total_sales;
        this.total_course_sales = report.data.total_course_sales;
        this.affilitor_payment = report.data.affilitor_payment;
      }
    },
    SearchData: _.debounce(function () {
      this.getResults();
    }, 1000),
  },
  watch: {
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
  },
};
</script>

<style></style>
